import React, { useEffect, useState } from 'react'
import { iconService } from '../../_services/'
import { useTranslation } from "react-i18next";
import { GoogleMap, LoadScript, Marker, Polyline, SymbolPath } from '@react-google-maps/api'
import { Row, Col } from "react-bootstrap";
import moment from 'moment'

function Map({sensor}) {

	const [map, setMap] = React.useState(null)
    const {t} = useTranslation('common');

    useEffect(() => {
		console.log(sensor)
    }, [sensor]);

    const onLoad = React.useCallback(function callback(map) {
	    setMap(map)
	    console.log('onLoad')
	}, [])

	const onUnmount = React.useCallback(function callback(map) {
		setMap(null)
	}, [])

	const onClick = (e) => [
		console.log(e.latLng.lat() + ',' + e.latLng.lng())
	]

    function handleCenter() {
        if (map === null) {
            return
        }
	}

	function getArrowLatLng(direction) {
		console.log(direction)
		return {
			lat: parseFloat(direction.split(',')[0]),
			lng: parseFloat(direction.split(',')[1])
		}
	}

	const lineSymbol = {
        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    };

	return (
		<>
			{ sensor && sensor.lat != 1000 && sensor.lng != 1000 &&
				<GoogleMap
			        mapContainerStyle={{ width: '100%', height: '600px' }}
			        onLoad={onLoad}
			        onUnmount={onUnmount}
			        onDragEnd={handleCenter}
			        center={sensor}
			        zoom={17}
			        onClick={onClick}
			    >
			        <Marker
		                position={sensor}
		                title={sensor.name}
		                marker={sensor}
		                icon={iconService.getMapIcon(sensor)}
		            />
		            { sensor.params['counter_1_direction'] &&
		                <Polyline
		                    path={[sensor, getArrowLatLng(sensor.params['counter_1_direction'])]}
		                    options={{
		                        strokeColor: 'blue',
		                        icons: [
			                        {
			                            icon: lineSymbol
			                        }
			                    ]
		                    }}
		                />
		            }
		            { sensor.params['counter_2_direction'] &&
		                <Polyline
		                    path={[sensor, getArrowLatLng(sensor.params['counter_2_direction'])]}
		                    options={{
		                        strokeColor: 'red',
		                        icons: [
			                        {
			                            icon: lineSymbol
			                        }
			                    ]
		                    }}
		                />
		            }
			    </GoogleMap>
		    }
	    </>
	)
}

export default Map;