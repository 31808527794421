import React, { useEffect, useState } from 'react'
import { resultService } from '../../_services/'
import { useTranslation } from "react-i18next";
import { HorizontalBar } from 'react-chartjs-2';
import { Row, Col } from "react-bootstrap";
import moment from 'moment'

function CounterChart({sensor}) {

	const [chartData, setChartData] = useState({})
	const [maxX, setMaxX] = useState(0)
    const {t} = useTranslation('common');

    useEffect(() => {
        var max = 0
        const datasets = []
        if (sensor.data && sensor.data['counter_1'] && sensor.data['counter_1'][sensor.id]) {
            var data = sensor.data['counter_1'][sensor.id]
			data.forEach((point => {
				var tmp = point.y
				point.y = point.x
				point.x = -tmp
				max = Math.max(max, tmp)
			}))
	        datasets.push({
	            label: 'Counter 1',
	            data: data,
	            barThickness: 10,
	            backgroundColor: 'blue'
	        })
        }
        if (sensor.data && sensor.data['counter_2'] && sensor.data['counter_2'][sensor.id]) {
            var data = sensor.data['counter_2'][sensor.id]
	        data.forEach((point => {
				var tmp = point.y
				point.y = point.x
				point.x = tmp
				max = Math.max(max, tmp)
			}))
			datasets.push({
	            label: 'Counter 2',
	            data: data,
	            barThickness: 10,
	            backgroundColor: 'red'
	        })
        }
        console.log(max)
        if (max % 5 != 0) {
            max = max + 5 - (max % 5)
        }
        setMaxX(max)
		setChartData({datasets: datasets})
    }, [sensor]);

    const options = {
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                ticks: {
                    min: -maxX,
                    max: maxX,
                    callback: function(value, index, ticks) {
                        return Math.abs(value);
                    }
                },
                tooltips: {
                    callback: function(value, index, ticks) {
                        return Math.abs(value);
                    }
                }
            }],
            yAxes: [{
                type: 'time',
                time: {
                    isoWeekday: true,
                    tooltipFormat: 'YYYY-MM-DD HH:mm',
                    displayFormats: {
                        millisecond: 'HH:mm:ss.SSS',
                        second: 'HH:mm:ss',
                        minute: 'HH:mm',
                        hour: 'HH:mm',
                    }
                },
                ticks: {
                    major: {
                        enabled: true
                    }
                },
                stacked: true
            }]
        },
        tooltips: {
            callbacks: {
                label: function(tooltipItem, data) {
                    var label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                        label += ': ';
                    }
                    label += Math.abs(tooltipItem.xLabel);
					return label
		        }
            }
        }
    }

	return (
		<HorizontalBar options={options} data={chartData} height={600} />
	)
}

export default CounterChart;